import React, { useEffect, useState } from "react";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import InquiryActions from "redux/InquiryData/actions";
import navigationAction from "redux/navigation/actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const { setAllData, setIpData, setPData, setStep } = InquiryActions;
const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

function Artwork(props) {
  const { settingdata, inquiry } = props;

  const { step, pData, ipData, allData } = inquiry;
  let { slug } = useParams();
  const dispatch = useDispatch();

  const [artwork1, setArtwork] = useState({ artwork: "Yes" });

  useEffect(() => {
    if (allData?.artwork) {
      setArtwork({ artwork: allData?.artwork });
    }
    // eslint-disable-next-line
  }, [allData?.artwork]);
  return (
    <>
      {/*  */}
      <div className="row wanting">
        <div className="col-lg-9 col-md-9 col-sm-12">
          <div className="isCatNameTag">
            <h4 className="p-title">{slug?.replace(/-/g, " ")}</h4>
          </div>
          <h5>
            <span className="mr-2">{pData.product_name}</span>
            {pData.size}
          </h5>
          <h3 className="sms-title">Get An Instant Price By SMS Now</h3>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-10">
          <div className="my-2">
            <h4 className="art">Artwork ?</h4>
            <div className="label">
              <label htmlFor="artwork1" style={{ cursor: "pointer" }}>
                <input
                  checked={
                    artwork1.artwork === undefined || artwork1.artwork === "Yes"
                      ? //|| allData.artwork === "Yes"
                        true
                      : false
                  }
                  id="artwork1"
                  type="radio"
                  name="r1"
                  className="mr-1"
                  onChange={e => {
                    setArtwork({
                      artwork: "Yes"
                    });
                  }}
                />
                {settingdata.artwork_label1}
              </label>
            </div>
            <div className="label">
              <label htmlFor="artwork2" style={{ cursor: "pointer" }}>
                <input
                  checked={
                    artwork1.artwork === "No"
                      ? //|| allData.artwork === "No"
                        true
                      : false
                  }
                  id="artwork2"
                  type="radio"
                  name="r1"
                  className="mr-1"
                  onChange={e => {
                    setArtwork({
                      artwork: "No"
                    });
                  }}
                />
                {settingdata.artwork_label2}
              </label>
            </div>
          </div>
          <div className="btn-align mb-3">
            <button
              type="button"
              className="btn btn_base_style my-2 mx-1"
              onClick={() => {
                console.log(ipData, "ipData ipData");
                dispatch(setStep(step - 1));
                dispatch(
                  setIpData({
                    ...ipData,
                    completedStep: ipData.completedStep - 1
                  })
                );
                dispatch(setAllData({ ...allData, quantity: undefined }));
              }}
            >
              Previous
            </button>
            <button
              type="button"
              className="btn btn_base_style my-2"
              onClick={() => {
                allData.category === "SHOPPING LIST TO DO LISTS"
                  ? dispatch(setStep(step + 0.5))
                  : dispatch(setStep(step + 1));
                dispatch(
                  setIpData({
                    ...ipData,
                    stepName: "Artwork Selected",
                    completedStep: ipData.completedStep + 1
                  })
                );

                if (artwork1.artwork === "") {
                  dispatch(setAllData({ ...allData, artwork: "Yes" }));
                } else {
                  dispatch(
                    setAllData({ ...allData, artwork: artwork1.artwork })
                  );
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
        <div className="col-12">
          <div className="my-4">
            <span className="cateogoryDescp">
              <div
                dangerouslySetInnerHTML={{
                  __html: pData.description
                }}
                className="notes-text"
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching,
    settingdata: state.settingdata.sdata,
    inquiry: state.inquiry
  };
};
// const mapDispatchToProps = () => {};

export default compose(
  withRouter,
  // enhancer,
  connect(
    mapStateToProps,
    { success, error, fetching, setuser }
    // mapDispatchToProps
  )
)(Artwork);
